import { ResizeObserver } from '@juggle/resize-observer'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import useMeasure from 'react-use-measure'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import { Button } from '../../molecules/Button/Button'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

// eslint-disable-next-line no-unused-vars
const Icon = styled(({ showMore, ...rest }) => <ExpandMore {...rest} />)`
  transform: rotate(${props => (props.showMore ? '180deg' : '0')});
  color: ${Colors.pastel['dark-blue']};
  margin-left: ${Spacings[4]};
`

const StyledButton = styled(Button)`
  width: 180px;
  margin-top: ${Spacings[22]};
  margin-bottom: ${Spacings[32]};
  &:hover {
    background-color: ${Colors.pastel['blue-light']};
  }
  &:focus,
  &:active {
    background-color: ${Colors.pastel['blue-light']};
  }

  ${Breakpoints.small} {
    margin-top: ${Spacings[12]};
    margin-bottom: ${Spacings[12]};
  }
`

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const FlexBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ShowMoreWrapper = styled(animated.div)`
  overflow: hidden;
`

// eslint-disable-next-line react/prop-types
export const ShowMore = ({ buttonText, children }) => {
  const [ref, { height }] = useMeasure({
    polyfill: ResizeObserver,
  })
  const [showMore, setShowMore] = useState(false)
  const props = useSpring({
    height: showMore ? height : 0,
    opacity: showMore ? 1 : 0,
  })

  return (
    <>
      <FlexWrapper>
        <StyledButton
          backgroundColor={Colors.background}
          onClick={() => setShowMore(show => !show)}
        >
          <FlexBtnWrapper>
            <Text variant='medium'>
              <Bold>{buttonText}</Bold>
            </Text>
            <Icon showMore={showMore} />
          </FlexBtnWrapper>
        </StyledButton>
      </FlexWrapper>

      <ShowMoreWrapper style={props}>
        <div ref={ref}>{children}</div>
      </ShowMoreWrapper>
    </>
  )
}
