import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { Text } from '../../atoms/Typography/Text'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: auto;
  grid-template-areas:
    'number header'
    '. text';
  margin-bottom: ${Spacings[42]};

  ${Breakpoints.small} {
    grid-template-columns: 40px auto;
    grid-template-areas:
      'number header'
      'text text';
  }
`

const StepNumber = styled(Header)`
  grid-area: number;
  border-bottom: 4px solid ${props => props.accentColor};
  color: ${Colors.pastel['darker-blue']};
  justify-self: center;
  padding: 0 7px;
  margin-bottom: ${Spacings[12]};
  height: fit-content;

  ${Breakpoints.small} {
    justify-self: left;
  }
`

const StepHeading = styled(Header)`
  grid-area: header;
  color: ${Colors.pastel['darker-blue']};
  margin-bottom: ${Spacings[12]};
`

const StepText = styled(Text)`
  grid-area: text;
  color: ${Colors.pastel['dark-blue']};
  text-align: justify;
`

export const Step = ({ heading, text, index, accentColor }) => {
  return (
    <GridWrapper>
      <StepNumber variant='heading3' accentColor={accentColor}>
        {index + 1}
      </StepNumber>
      <StepHeading variant='heading3'>{heading}</StepHeading>
      <StepText variant='large'>
        <Bold>{text}</Bold>
      </StepText>
    </GridWrapper>
  )
}

Step.propTypes = {
  heading: PropTypes.node,
  text: PropTypes.node,
  index: PropTypes.number,
  accentColor: PropTypes.string,
}
