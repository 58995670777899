import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { Text } from '../../atoms/Typography/Text'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'
import { steps } from '../RefferalSteps/ReferralSteps'
import { Step } from '../RefferalSteps/Step'
import { OfferTable } from './OfferTable'
import { ShowMore } from './ShowMore'

const StyledHeader = styled(Header)`
  padding-top: ${Spacings[42]};
  padding-bottom: ${Spacings[42]};

  ${Breakpoints.small} {
    padding-top: ${Spacings[22]};
    padding-bottom: ${Spacings[22]};
  }
`

const StyledSubHeader = styled(Header)`
  padding-top: ${Spacings[32]};

  ${Breakpoints.small} {
    padding-top: ${Spacings[12]};
  }
`

export const Offer = () => {
  const step = steps.map((stepProps, index) => (
    <Step key={stepProps.heading} index={index} {...stepProps} />
  ))

  return (
    <>
      <SectionWrapper backgroundColor={Colors.pastel['blue-light']}>
        <StyledHeader variant='heading2'>Oferta</StyledHeader>
      </SectionWrapper>

      <SectionWrapper>
        <StyledSubHeader variant='heading3'>NFZ</StyledSubHeader>
        <Text variant='large'>
          <Bold>
            Dostałeś skierowanie na rehabilitację i nie wiesz co z nim zrobić?
          </Bold>
        </Text>
        <br />
        <Text variant='large'>
          <Bold>
            Poniżej przygotowaliśmy dla Ciebie schemat postępowania, drogę jaką
            trzeba przejść aby skorzystać z rehabilitacji w ramach NFZ.
          </Bold>
        </Text>

        <ShowMore buttonText='Zobacz więcej'>{step}</ShowMore>
      </SectionWrapper>

      <SectionWrapper paddingBottom>
        <StyledSubHeader variant='heading3'>
          Oferta zabiegów prywatnych
        </StyledSubHeader>

        <ShowMore buttonText='Zobacz cennik'>
          <OfferTable />
        </ShowMore>
      </SectionWrapper>
    </>
  )
}
