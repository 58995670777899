import React from 'react'
import styled from 'styled-components'

import { Text } from '../../atoms/Typography/Text'
import { Breakpoints } from '../../styles/breakpoints'
import { Spacings } from '../../styles/spacings'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-auto-flow: row;
  border-top: 1px solid black;
  border-right: 1px solid black;
  margin: 0 5vw;

  ${Breakpoints.small} {
    margin: 0;
  }

  & > span {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }

  & li {
    margin: 0;
  }

  & ul {
    margin-bottom: 0;
  }
`

const PriceForTreatment = styled.div`
  display: grid;
  grid-column: 2;
  grid-row: 1 / 3;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`

const PriceForTreatmentText = styled(Text)`
  text-align: center;
  align-self: center;
`

const CenteredText = styled(Text)`
  text-align: center;
`

const PaddedText = styled(Text)`
  padding-left: ${Spacings[12]};
`

export const OfferTable = () => {
  return (
    <GridWrapper>
      <CenteredText variant='large'>RODZAJ ZABIEGU</CenteredText>
      <CenteredText variant='large'>FIZYKOTERAPIA</CenteredText>
      <PriceForTreatment>
        <PriceForTreatmentText variant='large'>
          Cena za zabieg
        </PriceForTreatmentText>
      </PriceForTreatment>
      <PaddedText variant='large'>Zabieg prądowy</PaddedText>
      <CenteredText variant='large'>10 zł</CenteredText>

      <PaddedText variant='large'>Pole magnetyczne</PaddedText>
      <CenteredText variant='large'>10 zł</CenteredText>
      <PaddedText variant='large'>Lampa Sollux</PaddedText>
      <CenteredText variant='large'>10 zł</CenteredText>
      <PaddedText variant='large'>Laseroterapia</PaddedText>
      <CenteredText variant='large'>10 zł</CenteredText>
      <PaddedText variant='large'>Krioterapia miejscowa</PaddedText>
      <CenteredText variant='large'>10 zł</CenteredText>
      <PaddedText variant='large'>Ultradźwięki / Fonoforeza</PaddedText>
      <CenteredText variant='large'>15 zł</CenteredText>
      <CenteredText variant='large'>KINEZYTERAPIA</CenteredText>
      <CenteredText variant='large'>Cena za usługę</CenteredText>

      <PaddedText variant='large'>
        Ćwiczenia (30 min)
        <br />
        <ul>
          <li>ogólno usprawniające</li>
          <li>w odciążeniu</li>
          <li>samowspomagane</li>
          <li>czynne oraz czynno-bierne</li>
        </ul>
      </PaddedText>
      <CenteredText variant='large'>30 zł</CenteredText>
      <PaddedText variant='large'>
        Ćwiczenia specjalistyczne terapia indywidualna
      </PaddedText>
      <CenteredText variant='large'>50 zł</CenteredText>

      <PaddedText variant='large'>Masaż</PaddedText>
      <CenteredText variant='large'>30zł - 50 zł</CenteredText>
      <PaddedText variant='large'>Kinesiotaping</PaddedText>
      <CenteredText variant='large'>30zł - 50 zł</CenteredText>
      <PaddedText variant='large'>
        Gimnastyka korekcyjna (45 min)
        <ul>
          <li>1 sesja</li>
          <li>10 sesji</li>
        </ul>
      </PaddedText>
      <CenteredText variant='large'>
        <br />
        50 zł
        <br />
        400 zł
      </CenteredText>
    </GridWrapper>
  )
}
