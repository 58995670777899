import React from 'react'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { Offer } from '../organisms/Offer/Offer'

const OfferPage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <Offer />
  </Main>
)

export default OfferPage
