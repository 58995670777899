import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Colors } from '../../styles/colors'
import { Spacings } from '../../styles/spacings'
import { Step } from './Step'

const StepsHeader = styled(Header)`
  color: ${Colors.pastel['darker-blue']};
  margin-bottom: ${Spacings[52]};
  text-align: center;
`

const Anchor = styled.a`
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
`

export const steps = [
  {
    heading: 'Skierowanie do poradni rehabilitacyjnej',
    text:
      'Jeżeli otrzymałeś takie skierowanie od swojego lekarza rodzinnego lub innego specjalisty, w pierwszej ' +
      'kolejności musisz udać się na konsultacje do lekarza specjalisty rehabilitacji. Podczas takiej wizyty, ' +
      'lekarz przepisuje konkretne zabiegi fizjoterapeutyczne na Twoje dolegliwości. W naszej poradni lekarzem ' +
      'specjalistą od rehabilitacji jest lek.med Jarosław Bal, możesz się do niego zarejestrować dzwoniąc do nas ' +
      ': np. tel lub link do zakładki kontakt?',
    accentColor: Colors.pastel.blue,
  },
  {
    heading: 'Skierowanie na zabiegi fizjoterapeutyczne',
    text:
      'Jeżeli trzymasz takie skierowanie w rękach, zapewne zauważysz na nim wypisane poszczególne zabiegi. ' +
      'Z takim skierowaniem najlepiej udać się osobiście do rejestracji, wówczas łatwiej jest ustalić termin ' +
      'Twojej rehabilitacji.',
    accentColor: Colors.pastel.pink,
  },
  {
    heading: 'Zabiegi fizjoterapeutyczne',
    text:
      'Termin zabiegów już ustalony, zostałeś poinformowany w rejestracji co potrzebujesz do zabiegów ale ' +
      'mimo to chcesz wiedzieć więcej? Przygotowaliśmy dla Ciebie praktyczny poradnik pacjenta (podlinkować), ' +
      'abyś mógł spokojnie i komfortowo przygotować się do rehabilitacji.',
    accentColor: Colors.pastel.green,
  },
  {
    heading: 'Po zakończeniu rehabilitacji',
    text:
      'Po tych dwóch tygodniach spędzonych z nami, zadowolony kończysz rehabilitację 😉 Ostatniego dnia ' +
      'otrzymujesz informację zwrotną do lekarza, który skierował Cię na zabiegi. Informację zwrotną najlepiej ' +
      'przekazać lekarzowi podczas wizyty kontrolnej. Jest to ważna informacja dla lekarza jak przepisana ' +
      'fizjoterapia wpłynęła na Ciebie.',
    accentColor: Colors.pastel.yellow,
  },
]

export const makeSteps = () =>
  steps.map((stepProps, index) => (
    <Step key={stepProps.heading} index={index} {...stepProps} />
  ))

export const ReferralSteps = () => (
  <>
    <Anchor id='nfz-steps' />
    <SectionWrapper
      paddingTop
      paddingBottom
      backgroundColor={Colors.pastel['violet-light']}
    >
      <StepsHeader variant='heading2'>
        Rehabilitacja w ramach NFZ: co zrobić ze skierowaniem?
      </StepsHeader>
      {makeSteps()}
    </SectionWrapper>
  </>
)

ReferralSteps.propTypes = {}
